const hasRecaptchaOption = (form, optionName) =>
  form.hasAttribute(`data-recaptchaoptions-${optionName}`);

export const createNewEvent = (eventName) =>
  new Event(eventName, { cancelable: true, bubbles: true });

export const defaultFormValidation = (form) => {
  // 'g-recaptcha' is if users want recaptcha with default 'submit' behavior.
  const submitButton = form.querySelector('.g-recaptcha');
  if (submitButton !== null) {
    submitButton.addEventListener('click', (event) => {
      event.preventDefault();
      if (form.checkValidity()) {
        form.dispatchEvent(createNewEvent('triggerRecaptcha'));
      } else {
        try {
          form.reportValidity();
        } catch {
          return;
        }
      }
    });
  }
};

export const renderRecaptcha = (form) => {
  const recaptchaWidget = form.querySelector('.recaptcha-holder');
  if (recaptchaWidget !== null) {
    // eslint-disable-next-line no-undef
    const recaptchaWidgetId = grecaptcha.render(recaptchaWidget, {
      sitekey: recaptchaWidget.getAttribute('data-sitekey'),
      size: 'invisible',
      badge: 'bottomleft',
      callback: function() {
        try {
          form.dispatchEvent(createNewEvent('recaptchaFinish'));
        } finally {
          form.dispatchEvent(createNewEvent('submit'));
          if (!hasRecaptchaOption(form, 'noautosubmit')) {
            form.submit();
          }
        }
      }
    });

    form.addEventListener('triggerRecaptcha', () => {
      // eslint-disable-next-line no-undef
      grecaptcha.execute(recaptchaWidgetId);
    });

    form.addEventListener('retriggerRecaptcha', () => {
      // eslint-disable-next-line no-undef
      grecaptcha.reset(recaptchaWidgetId);
      // eslint-disable-next-line no-undef
      grecaptcha.execute(recaptchaWidgetId);
    });
  }
};

export const recaptchaOnLoad = () => {
  document.dispatchEvent(createNewEvent('recaptchaLoaded'));
  const forms = document.forms;

  Array.from(forms).forEach((form) => {
    if (!hasRecaptchaOption(form, 'norender')) {
      renderRecaptcha(form);
      defaultFormValidation(form);
    } else {
      form.addEventListener('renderRecaptcha', () => {
        renderRecaptcha(form);
      });
    }
  });
};

// This allows the callback method to call correct function even after bundle.
window.recaptchaOnLoad = recaptchaOnLoad;

let recaptchaScript = document.createElement('script');
recaptchaScript.setAttribute(
  'src',
  'https://www.google.com/recaptcha/api.js?onload=recaptchaOnLoad&render=explicit'
);
recaptchaScript.setAttribute('async', '');
recaptchaScript.setAttribute('defer', '');
document.body.appendChild(recaptchaScript);
